import { cn } from '@/lib/cn';
import { Volume2, VolumeX } from 'lucide-react';
import { useState } from 'react';
import { Card } from './ui/card';

interface BlogImageProps {
    src: string;
    caption: string | JSX.Element;
    children?: React.ReactNode;
}

export const BlogImage: React.FC<BlogImageProps> = ({ src, caption, children }) => {
    return (
        <Card
            className={cn(
                'max-w-[600px]  m-4 mx-auto rounded-lg',
                'transition-shadow duration-100 shadow-md transform',
            )}
        >
            <img
                src={src}
                alt={"Blog Image -- " + caption.toString()}
                loading="lazy"
                className='w-full h-auto rounded-t-lg'
            />
            {children && <div className="px-6 p-6">{children}</div>}
        </Card>
    );
};

interface BlogVideoProps {
    src: string;
    caption: string | JSX.Element;
    autoplay?: boolean;
    loop?: boolean;
    controls?: boolean;
    children?: React.ReactNode;
    muteButton?: boolean;
    playsInline?: boolean;
}


export const BlogVideo: React.FC<BlogVideoProps> = ({
    src,
    autoplay = false,
    loop = false,
    controls = true,
    muteButton = false,
    playsInline = false,
    children,
}) => {

    const [isMuted, setIsMuted] = useState(true);
    const toggleMute = () => {
        setIsMuted(!isMuted);
    };

    const speakerToggle = (
        <button
            onClick={toggleMute}
            className="absolute z-50 top-4 left-4 p-2 rounded-full bg-white hover:bg-opacity-75 bg-opacity-50"
        >
            {isMuted ? <VolumeX /> : <Volume2 />}
        </button>
    );

    return (
        <Card
            className={cn(
                'max-w-[600px] m-4 mx-auto rounded-lg',
                'transition-shadow duration-100 shadow-md transform',
            )}
        >
            {muteButton && speakerToggle}
            <video
                src={src}
                autoPlay={autoplay}
                loop={loop}
                muted={isMuted}
                controls={controls}
                playsInline={playsInline}
                className="w-full h-auto rounded-t-lg"
            >
                <track kind="captions" />
            </video>
            {children && <div className="px-6 p-6">{children}</div>}
        </Card>
    );
};