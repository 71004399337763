import { useLocation } from '@remix-run/react';
import { Logo } from './Logo';
import Lore from '@/components/lore';
import FAQ from '@/components/faqs';
import { useEffect, useRef, useCallback } from 'react';

const Overview = () => {
    const faqSectionRef = useRef<HTMLDivElement>(null);
    const loreSectionRef = useRef<HTMLDivElement>(null);
    const location = useLocation();

    const scrollToSection = useCallback((elementRef: React.RefObject<HTMLDivElement>, retry: number = 0) => {
        if (elementRef.current) {
            elementRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (retry < 4) {
            setTimeout(() => scrollToSection(elementRef, retry + 1), 100 * (retry + 2));
        }
    }, []);

    // Handle initial load and navigation scroll
    useEffect(() => {
        if (location.hash === '#faq') {
            scrollToSection(faqSectionRef);
        } else if (location.hash === '#lore') {
            scrollToSection(loreSectionRef);
        }
    }, [location.hash, scrollToSection]);

    // Handle click events for hash links
    const handleHashClick = (e: React.MouseEvent<HTMLAnchorElement>, targetRef: React.RefObject<HTMLDivElement>) => {
        e.preventDefault();
        scrollToSection(targetRef);
    };

    return (
        <div className='flex flex-col gap-0 justify-center'>
            <div className='relative w-full min-h-[33vh] gap-8 flex flex-col items-center justify-center'>
                <div className='text-2xl font-bold flex justify-between items-center gap-12'>
                    <a href="#lore" onClick={(e) => handleHashClick(e, loreSectionRef)}>About</a>
                    <Logo
                        size={120}
                        className='h-200 flex-shrink-0'
                    />
                    <a href="#faq" onClick={(e) => handleHashClick(e, faqSectionRef)}>FAQ</a>
                </div>
                <div
                    style={{ lineHeight: '3.2rem' }}
                    className='text-xl pt-8 p-12 m-auto max-w-[800px] xl:max-w-[960px] text-pretty'>
                    <b className='text-4xl'>EPHEMERA</b>, an archaeological project by
                    <span className='ml-4 underline decoration-neutral-800/20'>Inscribing Atlantis</span>,
                    inscribes planetary ephemeris data on satoshis (the smallest unit of Bitcoin)
                    from the time they were mined,
                    creating digital artifacts that connect astronomical time to decentralized
                    Bitcoin block time.
                    <a href="#lore"
                        onClick={(e) => handleHashClick(e, loreSectionRef)}
                        className='text-blue-700 px-4 w-full text-xl font-medium p-2'>
                        Learn more...
                    </a>
                </div>

                <div ref={loreSectionRef} style={{ lineHeight: '3.2rem' }} className='text-xl pt-0 p-4 lg:p-12 m-auto max-w-[800px] xl:max-w-[960px] text-pretty'>
                    <div className='flex flex-row gap-20 justify-center mb-20'>
                        <Logo size={64} />
                        <Logo size={64} />
                        <Logo size={64} />
                    </div>
                    <Lore />
                </div>
                <div ref={faqSectionRef} style={{ lineHeight: '3.2rem' }} className='text-xl p-4 lg:p-12 m-auto max-w-[800px] xl:max-w-[960px] text-pretty'>
                    <div className='flex flex-row gap-20 justify-center mb-20'>
                        <Logo size={64} />
                        <Logo size={64} />
                        <Logo size={64} />
                    </div>
                    <FAQ />
                </div>
            </div>
        </div>
    );
};

export default Overview;