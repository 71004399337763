import { BlogImage } from '@/components/BlogImage';
import { motion } from 'framer-motion';
import kitControlsDisplaysUrl from '/ephemera_kit_controls_and_displays.png';

const Lore = () => (
  <motion.div
    transition={{
      duration: 0.2,
      delay: 0.05,
    }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
  >
      <div className='h-fit text-lg '>
        <h2 className='text-4xl'>
          About
        </h2>
        <p style={{ lineHeight: '2.4rem' }}>
          Imagine, thousands of years from now, an archaeologist from a civilization far removed from our own attempting to
          understand our digital civilization. If they are lucky, at some point in their excavation they dig up a Bitcoin
          core full node - a comprehensive, ordered log of messages between a network of nodes all around the planet Earth,
          a discovery which serves as a pivotal point in understanding the complex digital civilization that once flourished.
          They marvel at this extensive primary source document and wonder about how technologically advanced we might have
          been (how did they move those blocks?) and try to understand our religious and cultural customs through its
          inscriptions (they must have worshipped a frog). They will attempt to place our civilization in time and space,
          and they will find that the only timestamp is a string of numbers relative to our digital civilizations
          Year 0: January 1, 1970, the beginning of Unix Time.
        </p>

        <h3>Bitcoin is a Decentralized Clock</h3>
        <p style={{ lineHeight: '2.4rem' }}>
          When we look at Bitcoin from a Deep Time perspective, one of its exceptional, though lesser-known, functions
          emerges: <em>Bitcoin is a decentralized clock</em>. Every block since 2009, we all agree in a regular, decentralized
          manner what time it is on the Bitcoin blockchain. It&apos;s a precise means of timekeeping on the most extensive and
          expansive digital ledger that has ever existed. Its one unfortunate flaw is that it is tied to the Gregorian
          Calendar as its frame of reference, soiling its elegant status as a decentralized clock.
        </p>
        <p style={{ lineHeight: '2.4rem' }}>
          Before Bitcoin, the original form of decentralized time was astronomical timekeeping. Each individual, in an
          autonomous and independent fashion, can look into the sky and confirm that the sun is rising, or that the moon is
          full, and the motion of planetary objects is stable enough over time that one can track orbits to create a
          first-principles-derivable ledger of time over thousands of years.
        </p>
        <p style={{ lineHeight: '2.4rem' }}>
          If we consider the eternal legacy Bitcoin imparts on humanity as a primary source document of our digital
          civilization, <em>it becomes obvious that we should find a way to tether its decentralized timekeeping structure to
            the decentralized time of the natural world</em>. This is not a new idea - every civilization before us aligned
          its canonical megastructures along an astronomical axis in order to orient its temporal and spatial location and
          assert its civilization&apos;s place within the cosmos and flow of time (ex: Stonehenge and Serpent Mound&apos;s orientations
          &apos; deep
          understanding of their position in the universe and the passage of time.
        </p>

        <h3>Ephemera Preserves Deep Time</h3>
        <p style={{ lineHeight: '2.4rem' }}>
          Ephemera creates a tether between the decentralized time of our digital age (Bitcoin block height) with
          decentralized time of the natural world (astronomical timekeeping). We will regularly inscribe the exact planetary
          ephemeris data on sats from the time at which they were mined, according to Ordinal Theory. Sixteen of these
          inscriptions will be auctioned each difficulty adjustment, with the bidder choosing the date they wish to
          memorialize in decentralized time.
        </p>
        <BlogImage
          src={kitControlsDisplaysUrl}
          caption={"The Ephemera Kit, a 3D rendered, interactive digital artifact, displaying planetary ephemeris data on its screen, alongside the sun and moon phases on its sundial panel."}
        >
          An Ephemera Kit is a 3D rendered, interactive digital artifact, displaying planetary ephemeris data on its screen, alongside the sun and moon phases on its sundial panel.
        </BlogImage>
        <p style={{ lineHeight: '2.4rem' }}>
          Each kit’s attributes are algorithmically selected based on
          the date of its creation, ensuring each piece is a unique chronicle of time. These digital relics are collectibles
          as well as time capsules, preserving the planetary ephemeris data of the sat on which they are inscribed.
        </p>

        <p style={{ lineHeight: '2.4rem' }}>
          <strong>Bitcoin is the Digital Megalith of our civilization.</strong> Like every civilization before us, we cannot
          conceive of how we will rise and inevitably fall, and what clues we’ll be able to leave in our wake for the
          civilizations which will inevitably rise and fall in the next iteration of cyclical time. Ephemera endeavors to
          leave behind a trail of clues which can be followed by future archaeologists who seek to understand us and the
          context in which we lived. It is a humble attempt to make a mark in the sands of time, to connect with the conscious
          beings who look back on us from the future.
        </p>
      </div>

  </motion.div>
);

export default Lore;