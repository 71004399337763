import { Link } from '@remix-run/react';
import ReactMarkdown from 'react-markdown';

const questions = [
  {
    question: 'What is Ephemera?',
    answer: `Ephemera is an archaeological project that inscribes planetary ephemeris data on satoshis (the smallest unit of Bitcoin) from the time they were mined, creating digital artifacts that connect astronomical time to decentralized Bitcoin block time.`
  },
  {
    question: 'What is an Ephemera Kit?',
    answer: '![Ephemera Kit Overview](/ephemera-kit-overview.png "Ephemera Kit Overview")An Ephemera Kit is a 3D rendered, interactive digital artifact, displaying planetary ephemeris data on its screen, alongside the sun and moon phases on its sundial panel. Each kit’s attributes are algorithmically selected based on the date of its creation, ensuring each piece is a unique chronicle of time.'
  },
  {
    question: 'What kinds of random traits vary between Ephemera Kits?',
    answer: "![Ephemera Kit Traits Preview](/kit_traits_preview_01.mp4)We won't give away all the secrets just yet, but some of the traits that vary between kits include the color(s) of the kit and the specific orbit controller shown on the front of the kit. "
  },
  {
    question: 'How is the planetary data inscribed on the sats?',
    answer: 'The planetary data is inscribed using Bitcoin’s Ordinals protocol, which allows detailed information to be permanently etched onto the blockchain at the exact block height corresponding to the chosen date.'
  },
  {
    question: 'What is the Ephemera auction?',
    answer: 'The Ephemera auction allows participants to bid on specific dates to inscribe planetary ephemeris data on sats from the exact time they were mined, creating a tether between astronomical time and decentralized Bitcoin block time.'
  },
  {
    question: 'How do the auctions work?',
    answer: 'Every Bitcoin difficulty adjustment (approximately every two weeks), 16 new dates become available for auction. Bidders choose a specific date they want to memorialize, and the top 16 bids are inscribed. Winning bids are inscribed with an Ephemera Kit containing the planetary ephemeris data of their date. Only one Ephemera Kit will be made for each unique date on the Bitcoin timechain. The auction lasts until the next difficulty adjustment, when a new auction cycle begins.'
  },
  {
    question: 'When do the auctions take place?',
    answer: 'Auctions will occur every Bitcoin difficulty adjustment, which is approximately every two weeks. Each auction cycle begins at the start of the difficulty adjustment and ends at its conclusion.'
  },
  {
    question: 'Why can’t I select a date prior to January 9 2009?',
    answer: 'Ephemera tethers astronomical data to the exact time of the sat on which it is inscribed, so only dates which exist on the Bitcoin blockchain can be memorialized.'
  },
  {
    question: 'Where can I view all of the ephemera inscriptions?',
    answer: 'You can see all current ephemera kits under [the ephemera parent inscription](https://ordinals.com/inscription/f297186c8bd704fd9ea2d5d183930bfe2c4d2b8d4f48a337c9a61100e787599ci0). The genesis kit can be viewed [here](/kit/genesis).'
  },
];

const QuestionAnswer = ({ question, answer }: { question: string, answer: string }) => (
  <div >
    <h3>{question}</h3>
    <ReactMarkdown
      components={{
        img: ({ src, alt }) => {
          if (src?.endsWith('mp4')) {
            return (
              <video autoPlay loop muted playsInline className='rounded-lg w-3/4 m-auto pb-8'>
                <source src={src as string} type='video/mp4' />
              </video>
            )
          }
          return (
            <img src={src as string} alt={alt as string} className='rounded-lg w-3/4 m-auto pb-8' />
          )
        },
        p: ({ children }) => <p style={{ lineHeight: '2.4rem' }} className='text-lg'>{children}</p>,
        a: ({ children, href }) => {
          const isInternal = /^(\/(?!\/)|[^:]+(?=:|$))/.test(href as string);
          return isInternal ? (
            <Link to={href as string} className='font-bold underline'>{children}</Link>
          ) : (
            <a href={href as string} className='font-bold underline' target='_blank' rel='noreferrer'>{children}</a>
          );
        }
      }}
    >{answer}</ReactMarkdown>
  </div>
);

const FAQ = () => (
  <>
    <h2 className='text-4xl'>FAQs</h2>
    {questions.map(q => <QuestionAnswer key={q.question} question={q.question} answer={q.answer} />)}
  </>
);

export default FAQ;
